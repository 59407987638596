export const environment = {
  production: true,
  domain: 'https://family-staging.connectourkids.org',
  whiteListDomains: ['family-staging.connectourkids.org'], // whitelisted domain for @auth0/angular-jwt module
  apiUrl: '/api/v1',
  deviceTokenKeyName: 'device_token',
  deviceTokenFlagKeyName: 'device_token_flag',
  baseAssetUrl: '/assets',
  chromeExtensionId: 'ahfljofkjpcnafnkmoicplnmipeiknjd', // noboost
  environmentName: 'staging',
  extensionLinks: [
    {
      icon: './assets/images/chrome.png',
      name: 'Chrome',
      url: './assets/extensions/chrome.crx',
    },
  ],
  intercom: {
    appId: 'rz1hwc7q',
    enable: true,
  },
  organizationKeyName: 'organization',
  mixpanel: {
    enable: true,
  },
  ella: {
    websiteUrl: 'https://lifebook.ella.care'
  },
  familySearch: {
    apiKey: 'a0T3000000Bg1agEAB',
    environment: 'production',
    base: 'https://www.familysearch.org'
  },
  helloGrove: {
    apiUrl: 'https://hello-staging.connectourkids.org/graphql',
    websiteUrl: 'https://hello-staging.connectourkids.org',
  },
};
